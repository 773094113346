import React from 'react'
import Nav from '../functional/desktopNav.js';
import Footer from '../footer/desktop.js';
import text from '../../data/text.js';
import {phone as Phone, mail as Mail} from '../functional/icons.js';

const desktop = () => {
  return (
    <div className='column absolute' style={{top:'0'}}>
    
        <Nav />
        
        <div className='relative column' style={{width:'100vw',height:'30vw',margin:'0 0 2vw 0',background:'rgb(30,30,30)'}}>
            <img src={"https://sitowebcomposer.s3.eu-central-1.amazonaws.com/images/station.webp"} 
                 className='absolute'
                 style={{width:'100vw',height:'25vw',objectFit:'cover',filter:'grayscale(100%)',objectPosition:'50% 80%'}} />
            <div className='row absolute' style={{width:'40vw',alignSelf:'flex-start',alignItems:'flex-end',bottom:'0'}}>
                <h1 className='absolute' style={{width:'30vw',fontFamily:'Tourney',fontSize:'3vw',padding:'2vw',background:'rgba(240,240,240,0.9)',color:'rgb(30,30,30)',letterSpacing:'1.7vw',textAlign:'center',left:'0vw'}}>GIACOMO RENDINE</h1>
                <h2 className='absolute' style={{width:'15vw',fontFamily:'Tourney',padding:'1vw 0',background:'rgba(30,30,30,1)',color:'rgb(240,240,240)',fontSize:'2vw',textAlign:'center',right:'-5vw'}}>CONTACTS</h2>
            </div>

        </div>     

    <div className='column' style={{width:'80vw',height:'20vw',justifyContent:'space-evenly',alignItems:'flex-start',margin:'5vw 0'}}>
        <a className='row' href="mailto:giacomorendine@gmail.com" style={{width:'auto',textDecoration:'none',color:'rgb(240,240,240)',background:'rgb(30,30,30)',padding:'2vw 3vw'}}>
            <Mail svgStyle={{width:'2vw',fill:'rgb(240,240,240)'}} />
            <p style={{margin:'0 0 0 2vw',fontSize:'1.5vw'}}>giacomorendine@gmail.com</p>
       </a>
       <div className='row' style={{width:'auto',background:'rgb(30,30,30)',padding:'2vw 3vw'}}>
            <Phone svgStyle={{width:'2vw',fill:'rgb(240,240,240)'}} />
            <p style={{margin:'0 0 0 2vw',fontSize:'1.5vw'}}>+39 3292412670</p>
       </div>

    </div>
       
     

        
   
  

        
   
    <Nav/>
    <Footer />
    </div>

  )
}

export default desktop