import React from 'react';
import C0R3 from '../functional/c0r3-svg';
import fb from '../../assets/fb.png';
import insta from '../../assets/insta.png';
import yt from '../../assets/yt.png';
import spotify from '../../assets/spotify.png';
import imdb from '../../assets/imdb.png';
import sc from '../../assets/sc.png';
const desktop = () => {
  return (
    <div className='row' style={{background:'rgb(30,30,30)',width:'80vw',padding:'2vw 10vw',alignItems:'flex-start',justifyContent:'space-between'}}>

        <div className='column' style={{alignItems:'flex-start'}}>
        <p style={{fontFamily:'Tourney',fontSize:'1.5vw', margin:'0'}}>Giacomo Rendine</p>
        <a href="mailto:giacomorendine@gmail.com" style={{color:'rgb(240,240,240)',textDecoration:'none',margin:'1vw 0 '}}>
          <p style={{fontFamily:'Rajdhani',fontSize:'1.1vw', margin:'0'}}>giacomorendine@gmail.com</p>
        </a>
        <p style={{fontFamily:'Rajdhani',fontSize:'1.1vw', margin:'0'}}>+39 3292412670</p>
        </div>
        <div className='column' style={{alignItems:'flex-start'}}>
          <p style={{fontSize:'1.5vw',fontFamily:'Tourney',margin:'0 0 2vw 1.5vw'}}>follow</p>
          <div className='row'>
            <a href='https://www.facebook.com/giacomo.rendine'>
              <img src={fb} alt='' className='deskfollows' />
            </a>
            <a href='https://www.instagram.com/giacomorendine/'>
              <img src={insta} alt='' className='deskfollows' />
            </a>
            <a href='https://www.youtube.com/channel/UCT-usLkhz9UB4pkIXAvjVqQ'>
              <img src={yt} alt='' className='deskfollows' />

            </a>
            <a href='https://www.imdb.com/name/nm9731256/?ref_=nv_sr_srsg_0_tt_0_nm_3_q_giacomo%2520rendine'>
              <img src={imdb} alt='' className='deskfollows' />

            </a>
            <a href='https://open.spotify.com/intl-it/artist/4IWmI2dpwl2TdoHZGu5ZYY'>
              <img src={spotify} alt='' className='deskfollows' />

            </a>
            <a href='https://soundcloud.com/giacomo-rendine'>
              <img src={sc} alt='' className='deskfollows' />

            </a>
          </div>

        </div>
   
        <div className='column' style={{alignItems:'flex-start',alignSelf:'flex-end'}}>
          <p style={{fontFamily:'Roboto mono',fontSize:'0.8vw', margin:'0 0 0.5vw 0'}}>developed by</p>
          <C0R3 svgStyle={{fill:'rgb(240,240,240)',height:'1.5vw', margin:'0 0 0.4vw 0'}} />
        </div>

        
                        
    </div>
  )
}

export default desktop