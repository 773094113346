import React from 'react';

const soundCloudWidget = props => {
  return (
    <div className='column' style={{...props.wrapStyle,alignItems:'flex-start'}}>
        <p style={{fontFamily:'Rajdhani',margin:'0 0 0.5vw 0 '}}>{props.title.toUpperCase()}</p>
        <iframe title={props.title} width="100%" height="85%"  scrolling="no" frameBorder="no" allow="autoplay"
            src={props.src}>
        </iframe>
    </div>
  )
}

export default soundCloudWidget;