const text = [
    {
        language:"en",
        bio:[`Composer and orchestrator born in Rome in 1989,`,
             `He studies Composition for Music Applied to Images at the “S. Cecilia” graduating with top marks.`,
             `During his studies, he participated in various events as a concert performer and composer, working with artists such as Enzo De Caro, Vanessa Gravina and Amii Stewart in prestigious spaces such as the “Ennio Morricone” Auditorium, the Conciliazione Auditorium and the Opera Theater From Rome.`,
             `Winner of a "SIAE" scholarship, he obtained the Master in Film Scoring at the Digital Records studios in 2022, obtaining 110 cum laude and honorable mention.`,
             `This experience immediately led him to collaborate with various fellow composers active in the Italian soundtrack music scene such as Emanuele Bossi, Michele Braga, Andrea Farri, Giuliano Taviani, Nicola Tescari, Paolo Buonvino, Battista Lena and others as Arranger, Orchestrator and Composer of additional music for films and television series such as "La Stranezza", "Elf Me", "Vivi e Lascia Vivere" and others.`,
             `He collaborates every year, from 2018 to today, on the creation of the Alice nella Città festival, in close contact with the artistic directors Fabia Bettini and Gianluca Giannelli.`,
             `As a composer, his music has been performed by the Tuscan orchestra “la Filarmonique” by the “Pessoa” quartet and by the permanent orchestra of the Teatro Regio of Turin.`,
             `He composed the stage music for the theater show "I Poeti incontrano la Natura" in the setting of the Hospital Court of the Cloister of San Polo d'Enza and for the theater show Karatù Tarabài, an annual event in the city of Prato.`,
             `He collaborated in the creation of the short film “LOVE AND DESIRE” (Directed by Domenico Capalbo, produced by Fabrique Entertainment) as Composer and Sound Designer.`,
             `In 2021 he participated in the “Premio Carlo Gargioni” competition, winning first place in the Soundtracks section.`,
             `Latest works include the orchestration of the upcoming Marconi series (Music by Giuliano Taviani and Carmelo Travia) and the arrangement of the show “ANNA” performed at the Teatro Massimo in Palermo (Music by Paolo Buonvino).`,
             `He currently works as an arranger and orchestrator in the production of the soon-to-be-released series “Il Gattopardo” (music by Paolo Buonvino).`
        ],
        services:[
            {
                title:`COMPOSITION`,
                description:`Tailor-made composition services for short/feature films or concert hall, covering a variety of musical styles. Whether it's exciting orchestral music or a cozy chamber ensemble, let's talk about your project.`
            },
            {
                title:`ORCHESTRATION`,
                description:`Orchestration services or supervision for any type of project. I am classically trained and have a versatile feel for arranging any ensemble or instrument. Let me help you realize your musical vision!`
            },
            {
                title:`PROOFREADING`,
                description:`A small mistake in the final score or part can cost thousands of dollars in damage during the recording process. I started out as a proofreader and still proofread for many different projects.`
            },
            {
                title:`MIDI CLEAN/PREP`,
                description:`Need someone to clean up your MIDI or prepare a session for you? I can assist you quickly and efficiently.`
            },
            {
                title:`COPYING`,
                description:`I offer copy services and provide fine sheet music and parts to musicians.`
            }

        ]
    },
    {
        language:"it",
        bio:[`Compositore ed Orchestratore nato a Roma classe 1989,`,
             `Studia Composizione per la Musica Applicata alle Immagini presso il Conservatorio “S. Cecilia” laureandosi con il massimo dei voti.`,
             `Durante i suoi studi, partecipa a vari eventi come concertista e compositore, lavorando con artisti quali Enzo De Caro, Vanessa Gravina ed Amii Stewart in spazi prestigiosi come l’Auditorium “Ennio Morricone”, l’Auditorium della Conciliazione ed il Teatro dell'Opera di Roma.`,
             `Vincitore di una borsa di studio “SIAE” ha conseguito nel 2022, ottenendo 110 Lode e Menzione d’onore, il Master in Film Scoring presso gli studi della Digital Records.`,
             `Quest’esperienza lo porta subito a collaborare con vari colleghi compositori attivi nella scena musicale Italiana delle colonne sonore quali Emanuele Bossi, Michele Braga, Andrea Farri, Giuliano Taviani, Nicola Tescari, Paolo Buonvino, Battista Lena ed altri in qualità di Arrangiatore, Orchestratore e Compositore di musiche aggiuntive per film e serie televisive quali “La Stranezza”, “Elf Me”, “Vivi e Lascia vivere” ed altri.`,
             `Collabora ogni anno, dal 2018 ad oggi, alla realizzazione del festival Alice nella Città, in stretto contatto con i direttori artistici Fabia Bettini e Gianluca Giannelli.`,
             `In qualità di compositore, La sua musica è stata eseguita dall’orchestra Toscana “la Filarmonique” dal quartetto “Pessoa” e dall’orchestra stabile del Teatro Regio di Torino.`,
             `Ha composto le musiche di scena per lo spettacolo teatrale “I Poeti incontrano la Natura” nella cornice della Corte Ospitale del Chiostro di San Polo d'Enza e per lo spettacolo teatrale Karatù Tarabài, kermesse annuale della città di Prato.`,
             `Ha collaborato alla realizzazione del cortometraggio “LOVE AND DESIRE” (Regia di Domenico Capalbo, prodotto da Fabrique Entertainment) in qualità di Compositore e Sound Designer.`,
             `Nel 2021 partecipa al concorso “Premio Carlo Gargioni”, vincendo il primo posto nella sezione Colonne Sonore.`,
             `Gli ultimi lavori includono l’orchestrazione della serie Marconi di prossima uscita (Musiche di Giuliano Taviani e Carmelo Travia) e l’arrangiamento dello spettacolo “ANNA” eseguito al teatro Massimo di Palermo (Musiche di Paolo Buonvino).`,
             `Attualmente lavora come arrangiatore ed orchestratore nella produzione della serie “Il Gattopardo” (musiche di Paolo Buonvino) di prossima uscita.`
        ],
        services:[
            {
                title:``,
                description:``
            },
            {
                title:``,
                description:``
            },
            {
                title:``,
                description:``
            },
            {
                title:``,
                description:``
            },
            {
                title:``,
                description:``
            }

        ]
    },
]
export default text;