import React from 'react';
import MobNav from '../functional/mobNav';
import { Link } from 'react-router-dom';
import Footer from '../footer/mobile';
import fb from '../../assets/fb.png';
import insta from '../../assets/insta.png';
import yt from '../../assets/yt.png';
import spotify from '../../assets/spotify.png';
import imdb from '../../assets/imdb.png';
import sc from '../../assets/sc.png';
const mobHome =()=> {
    
  return (
    <div className="column absolute" style={{width:'100vw',justifyContent:'flex-start',background:'rgb(15,15,15)',color:'rgb(240,240,240)',top:'0'}} >
        
        <h1 style={{fontFamily:'Tourney',fontSize:'9vw',margin:'5vw 0',letterSpacing:'0.8vw',fontWeight:'normal'}}>GIACOMO RENDINE</h1>
        <img src={"https://sitowebcomposer.s3.eu-central-1.amazonaws.com/images/station.webp"} 
             style={{width:'100vw',height:'70vw',objectFit:'cover',filter:'grayscale(100%)',objectPosition:'50% 60%'}} />
        
        <h2 style={{fontFamily:'Tourney',fontSize:'6vw',fontWeight:'400',margin:'5vw 0',textAlign:'center'}}>film music composer and orchestrator</h2>
         
        <p className='mobSubtitle'>Selected orchestration works</p>
        <div className='row' style={{width:'100vw',height:'auto',margin:'0',justifyContent:'space-between'}}>
             <img className='homeWorksItemMob' src={"https://sitowebcomposer.s3.eu-central-1.amazonaws.com/images/locandine/la_stranezza.jpeg"} />
             <img className='homeWorksItemMob' src={"https://sitowebcomposer.s3.eu-central-1.amazonaws.com/images/locandine/elf_me.jpeg"} />
             <img className='homeWorksItemMob' src={"https://sitowebcomposer.s3.eu-central-1.amazonaws.com/images/locandine/cattiva_coscienza.jpeg"} />

        </div>
        <Link style={{margin:'5vw 0'}} className='links_mobile' to='/works'>view more &rarr;</Link>
        <p className='mobSubtitle'>Follow</p>
          <div className='row' style={{flexWrap:'wrap',width:'80vw',justifyContent:'space-between',alignContent:'center'}}>
            <a href='https://open.spotify.com/intl-it/artist/4IWmI2dpwl2TdoHZGu5ZYY' style={{margin:'0 10vw'}}>
              <img src={spotify} alt='' className='mobFollows' />
            </a>
            <a href='https://soundcloud.com/giacomo-rendine' style={{margin:'0 5vw'}}>
              <img src={sc} alt='' className='mobFollows' />

            </a>
            <a href='https://www.imdb.com/name/nm9731256/?ref_=nv_sr_srsg_0_tt_0_nm_3_q_giacomo%2520rendine'style={{margin:'0 5vw'}}>
              <img src={imdb} alt='' className='mobFollows' />

            </a>
            <a href='https://www.instagram.com/giacomorendine/'>
              <img src={insta} alt='' className='mobFollows' />
            </a>
            <a href='https://www.youtube.com/channel/UCT-usLkhz9UB4pkIXAvjVqQ'>
              <img src={yt} alt='' className='mobFollows' />

            </a>
            <a href='https://www.facebook.com/giacomo.rendine'>
              <img src={fb} alt='' className='mobFollows' />
            </a>
            
            
          </div>

        <p className='mobSubtitle'>Services</p>
        
         <div className='row relative' style={{margin:'0 0 5vw 0'}}>
             <img className='homeServItemMob' src={"https://sitowebcomposer.s3.eu-central-1.amazonaws.com/images/composition.webp"} />
             <p className='absolute' style={{fontFamily:'Tourney',fontSize:'5vw', padding:'3vw',background:'rgb(30,30,30)',top:'0',left:'0'}}>Composition</p>
         </div>
         <div className='row relative'>
             <img className='homeServItemMob' style={{filter:'grayscale(100%)'}} src={"https://sitowebcomposer.s3.eu-central-1.amazonaws.com/images/orchestration.webp"} />
             <p className='absolute' style={{fontFamily:'Tourney',fontSize:'5vw', padding:'3vw',background:'rgb(30,30,30)',top:'0',left:'0'}}>Orchestration</p>
         </div>
          <Link style={{margin:'5vw 0'}} className='links_mobile' to='/services'>discover services &rarr;</Link>

         
        <MobNav />
        <Footer />
    </div>
  
  )
}

export default mobHome