import React from 'react'
import MobNav from '../functional/mobNav';
import Footer from '../footer/mobile';

import sc from '../../assets/sc.png';
const mobile = () => {
  return (
    <div className="column absolute" style={{width:'100vw',justifyContent:'flex-start',background:'rgb(15,15,15)',color:'rgb(240,240,240)',top:'0'}}>
      <h1 style={{fontFamily:'Tourney',fontSize:'9vw',margin:'5vw 0',letterSpacing:'0.8vw',fontWeight:'normal'}}>GIACOMO RENDINE</h1>
        <img src={"https://sitowebcomposer.s3.eu-central-1.amazonaws.com/images/station.webp"} 
             style={{width:'100vw',height:'70vw',objectFit:'cover',filter:'grayscale(100%)',objectPosition:'50% 60%'}} />
        
        <h2 className='mobSubtitle'>Orchestration works</h2>

       <div className='row' style={{width:'80vw',height:'auto',margin:'0 0 5vw 0',justifyContent:'space-between',flexWrap:'wrap'}}>
            <img className='mobWorksItem' src={"https://sitowebcomposer.s3.eu-central-1.amazonaws.com/images/locandine/la_stranezza.jpeg"} />
            <img className='mobWorksItem' src={"https://sitowebcomposer.s3.eu-central-1.amazonaws.com/images/locandine/elf_me.jpeg"} />
            <img className='mobWorksItem' src={"https://sitowebcomposer.s3.eu-central-1.amazonaws.com/images/locandine/cattiva_coscienza.jpeg"} />
            <img className='mobWorksItem' src={"https://sitowebcomposer.s3.eu-central-1.amazonaws.com/images/locandine/anna.jpeg"} />
            <img className='mobWorksItem' src={"https://sitowebcomposer.s3.eu-central-1.amazonaws.com/images/locandine/il_commissario_ricciardi.jpeg"} />
            <img className='mobWorksItem' src={"https://sitowebcomposer.s3.eu-central-1.amazonaws.com/images/locandine/da_grandi.jpeg"} />
            <img className='mobWorksItem' src={"https://sitowebcomposer.s3.eu-central-1.amazonaws.com/images/locandine/vivi_e_lascia_vivere.jpeg"} />

       </div>

       <p className='mobSubtitle'>Listen my compositions on soundcloud</p>
       <a href='https://soundcloud.com/giacomo-rendine' style={{margin:'0 0 15vw 0'}}>
              <img src={sc} alt='' style={{width:'40vw'}}/>

            </a>
      <MobNav />
        <Footer />
    </div>
  )
}

export default mobile