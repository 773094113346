import React from 'react'
import {
  BrowserRouter,
  Route,
  Routes,
  NavLink,
} from "react-router-dom";
import Home from './home/home.js';
import Works from './works/works.js';
import About from './about/about.js';
import Services from './services/services.js';
import Contacts from './contacts/contacts.js';
import MobNav from './functional/mobNav.js';



const MAIN_ROUTER = () => {
  return (
    <div id='main' className="fullDev column relative" style={{background:'rgb(15,15,15)',color:'rgb(240,240,240)',overflowX:'hidden',overflowY:'scroll'}} >
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/works" element={<Works />} />
        <Route path="/services" element={<Services />} />
        <Route path="/about" element={<About />} />
        <Route path="/contacts" element={<Contacts />} />
      </Routes>
    </BrowserRouter>
    
    </div>
  )
}

export default MAIN_ROUTER;