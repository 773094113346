import React from 'react';

import C0R3 from '../functional/c0r3-svg';
const mobile = () => {
  return (
    <div className='column' style={{background:'rgb(30,30,30)',width:'80vw',padding:'5vw 10vw',justifyContent:'space-between'}}>

     
        <p style={{fontFamily:'Tourney',fontSize:'8vw', margin:'0'}}>Giacomo Rendine</p>
        <a href="mailto:giacomorendine@gmail.com" style={{color:'rgb(240,240,240)',textDecoration:'none',margin:'2vw 0 '}}>
          <p style={{fontFamily:'Rajdhani',fontSize:'5vw', margin:'0'}}>giacomorendine@gmail.com</p>
        </a>
        <p style={{fontFamily:'Rajdhani',fontSize:'5vw', margin:'0'}}>+39 3292412670</p>
       
       
        <div className='column'>
          <p style={{fontFamily:'Roboto mono',fontSize:'3vw', margin:'2vw 0'}}>developed by</p>
          <C0R3 svgStyle={{fill:'rgb(240,240,240)',height:'5.5vw'}} />
        </div>
          
     

        
                        
    </div>
  )
}

export default mobile;