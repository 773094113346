import React,{useEffect} from 'react';
import Desktop from './desktop.js';
import Mobile from './mobile.js';

const About =()=> {
  useEffect(()=>{
    document.getElementById('main').scrollTop = '0'
},[]);
    if (window.innerHeight<window.innerWidth){
        return <Desktop />;
      }else{
        return <Mobile />
      }
    
}

export default About;