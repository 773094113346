import React from 'react'
import Nav from '../functional/desktopNav.js';
import Footer from '../footer/desktop.js';
import text from '../../data/text.js';

const desktop = () => {
  return (
    <div className='column absolute' style={{top:'0'}}>
    
        <Nav />
        
        <div className='relative column' style={{width:'100vw',height:'30vw',margin:'0 0 2vw 0',background:'rgb(30,30,30)'}}>
            <img src={"https://sitowebcomposer.s3.eu-central-1.amazonaws.com/images/station.webp"} 
                 className='absolute'
                 style={{width:'100vw',height:'25vw',objectFit:'cover',filter:'grayscale(100%)',objectPosition:'50% 80%'}} />
            <div className='row absolute' style={{width:'40vw',alignSelf:'flex-start',alignItems:'flex-end',bottom:'0'}}>
                <h1 className='absolute' style={{width:'30vw',fontFamily:'Tourney',fontSize:'3vw',padding:'2vw',background:'rgba(240,240,240,0.9)',color:'rgb(30,30,30)',letterSpacing:'1.7vw',textAlign:'center',left:'0vw'}}>GIACOMO RENDINE</h1>
                <h2 className='absolute' style={{width:'15vw',fontFamily:'Tourney',padding:'1vw 0',background:'rgba(30,30,30,1)',color:'rgb(240,240,240)',fontSize:'2vw',textAlign:'center',right:'-5vw'}}>ABOUT</h2>
            </div>

        </div>     

    <div className='column' style={{margin:'2vw 0 5vw 0'}}>
       <p className='subtitle' style={{alignSelf:'flex-start'}}>Biography</p>
       <div className='row' style={{width:'80vw',justifyContent:'space-between'}}>
        <img style={{width:'37.5vw',height:'25vw',objectFit:'cover'}} 
             src={'https://sitowebcomposer.s3.eu-central-1.amazonaws.com/images/giacomo-rendine-2.webp'} />
        <img style={{width:'37.5vw',height:'25vw',objectFit:'cover'}} 
             src={'https://sitowebcomposer.s3.eu-central-1.amazonaws.com/images/giacomo-rendine.webp'} />
       </div>
       <div className='row' style={{width:'80vw',flexWrap:'wrap',height:'auto'}}>
            {
                text[0].bio.map((item,index)=>{
                    return(
                        <p key={item} style={{marginLeft:index%2===0?'-20vw':'20vw',fontFamily:'Rajdhani',fontSize:'1.4vw',width:'60vw',textAlign:'justify'}}>{item}</p>
                    );
                })
            }

        </div>
    </div>  

        
   
  

        
   
    <Nav/>
    <Footer />
    </div>

  )
}

export default desktop