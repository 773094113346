import React from 'react';
import MobNav from '../functional/mobNav';
import text from '../../data/text.js';
import Footer from '../footer/mobile';
const mobile = () => {
  return (
    <div className="column absolute" style={{width:'100vw',justifyContent:'flex-start',background:'rgb(15,15,15)',color:'rgb(240,240,240)',top:'0'}}>
      <h1 style={{fontFamily:'Tourney',fontSize:'9vw',margin:'5vw 0',letterSpacing:'0.8vw',fontWeight:'normal'}}>GIACOMO RENDINE</h1>
        <img src={"https://sitowebcomposer.s3.eu-central-1.amazonaws.com/images/station.webp"} 
             style={{width:'100vw',height:'70vw',objectFit:'cover',filter:'grayscale(100%)',objectPosition:'50% 60%'}} />
        
        <h2 className='mobSubtitle'>SERVICES</h2>
        <div className='column' style={{margin:'5vw 0'}}>
       
            {
                text[0].services.map(item=>{
                    return(
                      <div key={item.title} className='column'>
                        <p style={{fontFamily:'Tourney',fontSize:'5vw',margin:'5vw 0 0 0'}}>{item.title}</p>
                        <p style={{fontFamily:'Rajdhani',fontSize:'5vw',width:'80vw',textAlign:'justify'}}>{item.description}</p>
                      </div>
                    );
                })
            }

        </div>
   
      <MobNav />
      <Footer />
    </div>
  )
}

export default mobile