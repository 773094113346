const soundcloud = [
    {   
        title:"tema arabico per orchestra",
        url:"https://w.soundcloud.com/player/?url=https://soundcloud.com/giacomo-rendine/tema-arabico-per-orchestra",
        description:"",
        genere:"",
    },
    {   
        title:"remembers",
        url:"https://w.soundcloud.com/player/?url=https://soundcloud.com/giacomo-rendine/remembers",
        description:"",
        genere:"",
    },
    {   
        title:"Gli Angeli",
        url:"https://w.soundcloud.com/player/?url=https://soundcloud.com/giacomo-rendine/gli-angeli",
        description:"",
        genere:"",
    },
    {   
        title:"ending - piano and string",
        url:"https://w.soundcloud.com/player/?url=https://soundcloud.com/giacomo-rendine/ending-archi-e-piano",
        description:"",
        genere:"",
    },
    {   
        title:"dancing with the flowers",
        url:"https://w.soundcloud.com/player/?url=https://soundcloud.com/giacomo-rendine/dancing-with-the-flowers-24b",
        description:"",
        genere:"",
    },
    {   
        title:"Tema - piano & cello",
        url:"https://w.soundcloud.com/player/?url=https://soundcloud.com/giacomo-rendine/tema",
        description:"",
        genere:"",
    },
]

export default soundcloud;