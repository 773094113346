import React from 'react'
import MobNav from '../functional/mobNav';
import Footer from '../footer/mobile';

import text from '../../data/text.js';
const mobile = () => {
  return (
    <div className="column absolute" style={{width:'100vw',justifyContent:'flex-start',background:'rgb(15,15,15)',color:'rgb(240,240,240)',top:'0'}}>
      <h1 style={{fontFamily:'Tourney',fontSize:'9vw',margin:'5vw 0',letterSpacing:'0.8vw',fontWeight:'normal'}}>GIACOMO RENDINE</h1>
        <img src={"https://sitowebcomposer.s3.eu-central-1.amazonaws.com/images/station.webp"} 
             style={{width:'100vw',height:'70vw',objectFit:'cover',filter:'grayscale(100%)',objectPosition:'50% 60%'}} />
        
        <h2 className='mobSubtitle'>Biography</h2>

        <img style={{width:'80vw',height:'50vw',objectFit:'cover'}} 
             src={'https://sitowebcomposer.s3.eu-central-1.amazonaws.com/images/giacomo-rendine.webp'} />
        
       <div className='row' style={{width:'80vw',flexWrap:'wrap',height:'auto'}}>
            {
                text[0].bio.map((item)=>{
                    return(
                        <p key={item} style={{fontFamily:'Rajdhani',fontSize:'5vw',width:'80vw',textAlign:'justify'}}>{item}</p>
                    );
                })
            }

        </div>
        <img style={{width:'80vw',height:'50vw',objectFit:'cover',margin:'0 0 10vw 0'}} 
             src={'https://sitowebcomposer.s3.eu-central-1.amazonaws.com/images/giacomo-rendine-2.webp'} />

      <MobNav />
      <Footer />
    </div>
  )
}

export default mobile