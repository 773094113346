import React, {useEffect} from 'react';
import SoundCloud from '../functional/soundCloudWidget';
import soundCloudData from '../../data/soundcloud';
import text from '../../data/text';
import Footer from '../footer/desktop';
import Nav from '../functional/desktopNav';
import { Link } from 'react-router-dom';
const DeskHome =()=> {
    
    
  return (
    
      <div className='column absolute' style={{top:'0'}}>
    
        <Nav />
        
        <div className='relative column' style={{width:'100vw',height:'45vw',margin:'0 0 5vw 0',background:'rgb(30,30,30)'}}>
            <img src={"https://sitowebcomposer.s3.eu-central-1.amazonaws.com/images/station.webp"} 
                 style={{width:'100vw',height:'45vw',objectFit:'cover',filter:'grayscale(100%)',objectPosition:'50% 60%'}} />
            <div className='column absolute' style={{width:'100vw',background:'rgba(240,240,240,0.9)',color:'rgb(30,30,30)'}}>
                <h1 style={{fontFamily:'Tourney',fontSize:'5vw',margin:'2vw 0 0 0',letterSpacing:'1.7vw'}}>GIACOMO RENDINE</h1>
                <h2 style={{fontFamily:'Tourney',fontSize:'2vw',fontWeight:'900',margin:'0 0 2vw 0'}}>film music composer and orchestrator</h2>
            </div>

        </div>   

       <div className='column'>
        <p className='subtitle' style={{alignSelf:'flex-start'}}>Selected orchestration works</p>
        <div className='row' style={{width:'80vw',height:'auto',margin:'0',justifyContent:'space-between'}}>
             <img className='homeWorksItem' src={"https://sitowebcomposer.s3.eu-central-1.amazonaws.com/images/locandine/la_stranezza.jpeg"} />
             <img className='homeWorksItem' src={"https://sitowebcomposer.s3.eu-central-1.amazonaws.com/images/locandine/elf_me.jpeg"} />
             <img className='homeWorksItem' src={"https://sitowebcomposer.s3.eu-central-1.amazonaws.com/images/locandine/cattiva_coscienza.jpeg"} />

        </div>
        <Link style={{margin:'1vw 0 0 0'}} className='links_desktop' to='/works'>view more &rarr;</Link>
       </div>

       <div className='column' style={{margin:'5vw 0'}}>
        <p className='subtitle' style={{alignSelf:'flex-start'}}>Services</p>
        <div className='row' style={{width:'80vw',height:'auto',margin:'0',justifyContent:'space-between'}}>
            <div className='row relative'>
                <img className='homeServItem' src={"https://sitowebcomposer.s3.eu-central-1.amazonaws.com/images/composition.webp"} />
                <p className='absolute' style={{fontFamily:'Tourney',fontSize:'1.3vw', padding:'1vw 1.5vw',background:'rgb(30,30,30)',bottom:'0',right:'0'}}>Composition</p>
            </div>
            <div className='row relative'>
                <img className='homeServItem' style={{filter:'grayscale(100%)'}} src={"https://sitowebcomposer.s3.eu-central-1.amazonaws.com/images/orchestration.webp"} />
                <p className='absolute' style={{fontFamily:'Tourney',fontSize:'1.3vw', padding:'1vw 1.5vw',background:'rgb(30,30,30)',bottom:'0',right:'0'}}>Orchestration</p>
            </div>
             <Link style={{margin:'1vw 0 0 0'}} className='links_desktop' to='/services'>discover services &rarr;</Link>
        </div>
        
       </div>
       

   


    <div className='column' style={{width:'80vw',margin:'0 0 7.5vw 0'}}>

    <p className='subtitle' style={{alignSelf:'flex-start'}}>Scorings</p>

        
        <div className='row' style={{width:'80vw',height:'20vw',alignContent:'center',justifyContent:'space-between'}}>
        
        <iframe width="49%" height="100%"
            src="https://www.youtube.com/embed/q2MFIdhoVT4">
        </iframe>
        <iframe width="49%" height="100%"
            src="https://www.youtube.com/embed/UJ4KCu4ci9Y">
        </iframe>

        </div>

        <div className='row' style={{width:'80vw',height:'20vw',alignContent:'center',justifyContent:'space-between',margin:'1vw 0'}}>
        
        <iframe width="49%" height="100%"
            src="https://www.youtube.com/embed/Uve_6GQrhoo">
        </iframe>
        <iframe width="49%" height="100%"
            src="https://www.youtube.com/embed/y1dwTf3LoeQ">
        </iframe>

        </div>
        <a style={{margin:'1vw 0 0 0'}} className='links_desktop' href='https://www.youtube.com/channel/UCT-usLkhz9UB4pkIXAvjVqQ'>view more on You Tube &rarr;</a>

        

        
    </div>
    <Nav/>
    <Footer />
    </div>
 
  )
}

export default DeskHome;