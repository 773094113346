import React from 'react';
import { NavLink } from 'react-router-dom';

const desktopNav =()=> {
  return (
    <nav id='deskNav' className='row' style={{width:'80vw',justifyContent:'space-between'}}>
        <NavLink to="/"
                 className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : ""}>
            HOME
        </NavLink>
        <NavLink to="/works"
                 className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : ""}>
            WORKS
        </NavLink>
        <NavLink to="/services"
                 className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : ""}>
            SERVICES
        </NavLink>
        <NavLink to="/about"
                 className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : ""}>
            ABOUT
        </NavLink>
        <NavLink to="/contacts"
                 className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : ""}>
            CONTACTS
        </NavLink>
    </nav>
  )
}

export default desktopNav;