import React from 'react'
import Nav from '../functional/desktopNav.js';
import Footer from '../footer/desktop.js';
import text from '../../data/text.js';

const desktop = () => {
  return (
    <div className='column absolute' style={{top:'0'}}>
    
    <Nav />
        
        <div className='relative column' style={{width:'100vw',height:'30vw',margin:'0 0 2vw 0',background:'rgb(30,30,30)'}}>
            <img src={"https://sitowebcomposer.s3.eu-central-1.amazonaws.com/images/station.webp"} 
                 className='absolute'
                 style={{width:'100vw',height:'25vw',objectFit:'cover',filter:'grayscale(100%)',objectPosition:'50% 80%'}} />
            <div className='row absolute' style={{width:'40vw',alignSelf:'flex-start',alignItems:'flex-end',bottom:'0'}}>
                <h1 className='absolute' style={{width:'30vw',fontFamily:'Tourney',fontSize:'3vw',padding:'2vw',background:'rgba(240,240,240,0.9)',color:'rgb(30,30,30)',letterSpacing:'1.7vw',textAlign:'center',left:'0vw'}}>GIACOMO RENDINE</h1>
                <h2 className='absolute' style={{width:'15vw',fontFamily:'Tourney',padding:'1vw 0',background:'rgba(30,30,30,1)',color:'rgb(240,240,240)',fontSize:'2vw',textAlign:'center',right:'-5vw'}}>SERVICES</h2>
            </div>

        </div>     
  

        <div className='column' style={{margin:'5vw 0'}}>
       <div className='row' style={{width:'90vw',flexWrap:'wrap',height:'auto',justifyContent:'space-evenly'}}>
            {
                text[0].services.map(item=>{
                    return(
                      <div key={item.title} className='column'>
                        <p style={{fontFamily:'Tourney',fontSize:'1.6vw',margin:'2vw 0 0 0'}}>{item.title}</p>
                        <p style={{fontFamily:'Rajdhani',fontSize:'1.4vw',width:'25vw',textAlign:'justify'}}>{item.description}</p>
                      </div>
                    );
                })
            }

        </div>
    </div>  

   
    <Nav/>
    <Footer />
    </div>

  )
}

export default desktop