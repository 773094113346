import React from 'react'
import Nav from '../functional/desktopNav';
import Footer from '../footer/desktop.js';
import text from '../../data/text.js';
import { Link } from 'react-router-dom';
import soundCloudData from '../../data/soundcloud.js';
import SoundCloud from '../functional/soundCloudWidget.js';

const desktop = () => {
  return (
    <div className='column absolute' style={{top:'0'}}>
    
        <Nav />
        
        <div className='relative column' style={{width:'100vw',height:'30vw',margin:'0 0 2vw 0',background:'rgb(30,30,30)'}}>
            <img src={"https://sitowebcomposer.s3.eu-central-1.amazonaws.com/images/station.webp"} 
                 className='absolute'
                 style={{width:'100vw',height:'25vw',objectFit:'cover',filter:'grayscale(100%)',objectPosition:'50% 80%'}} />
            <div className='row absolute' style={{width:'40vw',alignSelf:'flex-start',alignItems:'flex-end',bottom:'0'}}>
                <h1 className='absolute' style={{width:'30vw',fontFamily:'Tourney',fontSize:'3vw',padding:'2vw',background:'rgba(240,240,240,0.9)',color:'rgb(30,30,30)',letterSpacing:'1.7vw',textAlign:'center',left:'0vw'}}>GIACOMO RENDINE</h1>
                <h2 className='absolute' style={{width:'15vw',fontFamily:'Tourney',padding:'1vw 0',background:'rgba(30,30,30,1)',color:'rgb(240,240,240)',fontSize:'2vw',textAlign:'center',right:'-5vw'}}>WORKS</h2>
            </div>

        </div>   

    <div className='column'>
       <p className='subtitle' style={{alignSelf:'flex-start'}}>Orchestration works</p>
       <div className='row' style={{width:'80vw',height:'auto',margin:'0 0 5vw 0',justifyContent:'space-between',flexWrap:'wrap'}}>
            <img className='deskWorksItem' src={"https://sitowebcomposer.s3.eu-central-1.amazonaws.com/images/locandine/la_stranezza.jpeg"} />
            <img className='deskWorksItem' src={"https://sitowebcomposer.s3.eu-central-1.amazonaws.com/images/locandine/elf_me.jpeg"} />
            <img className='deskWorksItem' src={"https://sitowebcomposer.s3.eu-central-1.amazonaws.com/images/locandine/cattiva_coscienza.jpeg"} />
            <img className='deskWorksItem' src={"https://sitowebcomposer.s3.eu-central-1.amazonaws.com/images/locandine/anna.jpeg"} />
            <img className='deskWorksItem' src={"https://sitowebcomposer.s3.eu-central-1.amazonaws.com/images/locandine/il_commissario_ricciardi.jpeg"} />
            <img className='deskWorksItem' src={"https://sitowebcomposer.s3.eu-central-1.amazonaws.com/images/locandine/da_grandi.jpeg"} />
            <img className='deskWorksItem' src={"https://sitowebcomposer.s3.eu-central-1.amazonaws.com/images/locandine/vivi_e_lascia_vivere.jpeg"} />

       </div>
    </div>  

        
   
    <div className='column'>
        <p className='subtitle' style={{alignSelf:'flex-start'}}>Compositions</p>
        <div className='row' style={{width:'80vw',flexWrap:'wrap',alignContent:'center',justifyContent:'space-between'}}>
        {
          soundCloudData.map(item=>{
            return(
              <SoundCloud 
                    key={item.title}
                    wrapStyle={{width:'25vw',height:'10vw',margin:'1vw 0.5vw '}} 
                    src={item.url} 
                    title={item.title} />
            );
          })
        }
        </div>
        <a style={{margin:'1vw 0 5vw 0'}} className='links_desktop' href='https://soundcloud.com/giacomo-rendine'>listen more on Sound Cloud &rarr;</a>
    </div>
        
    <Nav/>
    <Footer />
    </div>

  )
}

export default desktop