import React from 'react';
import MobNav from '../functional/mobNav';
import Footer from '../footer/mobile';

import {phone as Phone, mail as Mail} from '../functional/icons.js';
const mobile = () => {
  return (
    <div className="column absolute" style={{width:'100vw',justifyContent:'flex-start',background:'rgb(15,15,15)',color:'rgb(240,240,240)',top:'0'}}>
      <h1 style={{fontFamily:'Tourney',fontSize:'9vw',margin:'5vw 0',letterSpacing:'0.8vw',fontWeight:'normal'}}>GIACOMO RENDINE</h1>
        <img src={"https://sitowebcomposer.s3.eu-central-1.amazonaws.com/images/station.webp"} 
             style={{width:'100vw',height:'70vw',objectFit:'cover',filter:'grayscale(100%)',objectPosition:'50% 60%'}} />
        
        <h2 className='mobSubtitle'>CONTACTS</h2>
        <div className='column' style={{height:'50vh'}}>
        <a className='row' href="mailto:giacomorendine@gmail.com" style={{width:'auto',textDecoration:'none',color:'rgb(240,240,240)',background:'rgb(30,30,30)',padding:'5vw',margin:'3vw 0'}}>
            <Mail svgStyle={{width:'7vw',fill:'rgb(240,240,240)'}} />
            <p style={{margin:'0 0 0 7vw',fontSize:'5vw'}}>giacomorendine@gmail.com</p>
       </a>
       <div className='row' style={{width:'auto',background:'rgb(30,30,30)',padding:'5vw',margin:'3vw 0'}}>
            <Phone svgStyle={{width:'7vw',fill:'rgb(240,240,240)'}} />
            <p style={{margin:'0 0 0 7vw',fontSize:'5vw'}}>+39 3292412670</p>
       </div>

    </div>
      <MobNav />
      <Footer />
    </div>
  )
}

export default mobile