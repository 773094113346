import React from 'react';

const c0r3_sign = props => {
  return (
    <svg style={props.svgStyle} version="1.1" viewBox="0 0 256.63 66.146" xmlns="http://www.w3.org/2000/svg">
     <g transform="translate(-5.5963 -72.76)">
      <path d="m5.5961 99.88v-10.583l28.642-16.536 5.7284 3.3073v10.583l-5.7284-3.3073z" style={props.pathStyle}/>
      <path d="m5.5963 105.17 9.1654-5.2917v17.198l14.894 8.599-9.1654 5.2917-14.894-8.599z" style={props.pathStyle}/>
      <path d="m25.073 133.61 28.642-16.536v-6.6146l9.1655 5.2917v6.6146l-28.642 16.536z" style={props.pathStyle}/>
      <path d="m89.231 130.97-14.894-8.599v-33.073l28.642-16.536 9.1654 5.2917-28.642 16.536v22.49l14.894 8.599z" style={props.pathStyle}/>
      <path d="m107.56 85.99 9.1654-5.2917 14.894 8.599v33.073l-28.642 16.536-9.1654-5.2917 28.642-16.536v-22.49z" style={props.pathStyle}/>
      <path d="m152.24 136.26-9.1654-5.2917v-43.656l11.457-6.6146 9.1654 5.2917-11.457 6.6146v18.521l37.807 21.828-9.1654 5.2917-28.642-16.536z" style={props.pathStyle}/>
      <path d="m159.12 78.052 9.1654-5.2917 25.205 14.552v29.104l-11.457 6.6146-9.1654-5.2917 11.457-6.6146v-18.521z" style={props.pathStyle}/>
      <path d="m253.06 127.66-19.477 11.245-28.642-16.536v-6.6146l9.1654-5.2917v6.6146l19.477 11.245 10.311-5.9531z" style={props.pathStyle}/>
      <path d="m229 103.19v5.2917l28.642 16.536 4.5827-2.6458v-5.2917l-28.642-16.536z" style={props.pathStyle}/>
      <path d="m227.86 86.651v-10.583l5.7284-3.3073 28.642 16.536v22.49l-9.1654-5.2917v-11.906l-19.477-11.245z" style={props.pathStyle}/>
     </g>
    </svg>
  )
}

export default c0r3_sign;

