import React from 'react';
import { NavLink } from 'react-router-dom';
const toggler =()=> {
    if (document.getElementById('mobNav').style.transform==='translateX(50vw)'){
        document.getElementById('mobNav').style.transform='translateX(0)';
    }else{
        document.getElementById('mobNav').style.transform='translateX(50vw)';
    }
}
const mobNav = () => {
  return (
    <div className='column' id='mobNav' style={{background:'rgb(240,240,240)',transition:'300ms ease-in-out',padding:'0 5vw 0 0',alignItems:'flex-end',width:'45vw',height:'100vh',position:'fixed',top:'0',right:'0',transform:'translateX(50vw)'}}>
        <NavLink to="/"
                 className={({ isActive, isPending }) => isActive ? "active" : ""}>
            HOME
        </NavLink>
        <NavLink to="/works"
                 className={({ isActive, isPending }) => isActive ? "active" : ""}>
            WORKS
        </NavLink>
        <NavLink to="/services"
                 className={({ isActive, isPending }) => isActive ? "active" : ""}>
            SERVICES
        </NavLink>
        <NavLink to="/about"
                 className={({ isActive, isPending }) => isActive ? "active" : ""}>
            ABOUT
        </NavLink>
        <NavLink to="/contacts"
                 className={({ isActive, isPending }) => isActive ? "active" : ""}>
            CONTACTS
        </NavLink>
        <div onClick={toggler} className='absolute row' style={{left:'-24vw',bottom:'8vh',width:'24vw',height:'15vw',background:'rgb(240,240,240)',borderRadius:'2vw 0 0 2vw'}}>
            <p style={{fontFamily:'Tourney',fontSize:'6vw',fontWeight:'400',color:'rgb(30,30,30)'}}>
                MENU
            </p>
        </div>
    </div>
  )
}

export default mobNav